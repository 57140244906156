import fetch from "cross-fetch";

function buildApiCall(endpoint, method, requestData) {
  let serviceLocation = endpoint;
  if (!serviceLocation.startsWith('/')) {
    serviceLocation = '/' + endpoint;
  }

  const requestDescriptor = {
    mode: 'cors',
    credentials: 'include',
    method: method,
  };

  if (requestDescriptor.headers === undefined) {
    requestDescriptor.headers = {};
  }

  if (method === 'POST') {
    requestDescriptor.headers['Content-Type'] = 'application/json';
    requestDescriptor.body = JSON.stringify(requestData);
  }

  return fetch(`${process.env.GATSBY_API_URL}` + serviceLocation, requestDescriptor)
}

const HTTP_GET = "GET";

export const fetchAllVolunteers = () => buildApiCall('/v1/public/volunteers', HTTP_GET)
export const fetchVolunteer = (volunteerId) => buildApiCall('/v1/public/volunteer/' + volunteerId, HTTP_GET)