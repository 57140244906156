import React, { useEffect, useState } from 'react';
import { fetchVolunteer } from "../services/api";
import { Router } from '@reach/router';
import Error_404 from "./404";
import DefaultLayout from "../layouts/DefaultLayout";

const ERROR_TECHNICAL_ERROR = "-1";
const ERROR_RECORD_NOT_FOUND = "404";

const crumbs = [
  {
    title: 'Αρχική',
    url: '/'
  },
  {
    title: 'Προφίλ Εθελοντή'
  }];

function convertEducationLevel(educationLevel) {
  if (educationLevel === 'BACHELOR') {
    return 'Προπτυχιακές Σπουδές';
  } else if (educationLevel === 'MASTERS') {
    return 'Μεταπτυχιακές Σπουδές';
  } else if (educationLevel === 'PHD') {
    return 'Διδακτορικές Σπουδές';
  }
}

function convertLineOfEducation(lineOfEducation) {
  return lineOfEducation.title.el;
}


function VolunteerProfile({volunteer}) {
  return <>
    <div className='col-md'>
      <div className='container'>
        <div className='row'>
          <img src={`${process.env.GATSBY_API_URL}/v1/media/` + volunteer.profilePhotoId}
               alt={volunteer.firstName + ' ' + volunteer.lastName}
               width='208' height='208' style={{objectFit: 'cover'}} />
        </div>
        <div className='row'>
          {volunteer.firstName} {volunteer.lastName}
        </div>
        <div className='row'>
          {convertLineOfEducation(volunteer.lineOfEducation)}
        </div>
        <div className='row'>
          <h5>{volunteer.city}, {volunteer.country}</h5>
        </div>
        <div className='row'>
          <h5>{convertEducationLevel(volunteer.educationLevel)}</h5>
        </div>
      </div>
    </div>
    <div className={'col'}>
      <h3>Προφίλ Εθελοντή - Επιστήμονα</h3>
      {
        volunteer.selfDescription !== undefined &&
        volunteer.selfDescription.length > 0 &&
        <>
          <h3>Λίγα λόγια για εμένα:</h3>
          {volunteer.selfDescription}
        </>
      }
      {
        volunteer.careerReasons !== undefined &&
        volunteer.careerReasons.length > 0 &&
        <>
          <h3>Γιατί δίαλεξα την καριέρα μου:</h3>
          {volunteer.careerReasons}
        </>
      }
      {
        volunteer.joiningReasons !== undefined &&
        volunteer.joiningReasons.length > 0 &&
        <>
          <h3>Γιατί έγινα εθελοντής στο R.E.A.L Science:</h3>
          {volunteer.joiningReasons}
        </>
      }
    </div>
  </>
}

function VolunteerView(props) {
  const { volunteerId } = props;
  const [data, setData] = useState({});

  useEffect(() => {
    fetchVolunteer(volunteerId)
      .catch(error => {
        const detailedError = new Error("Technical error");
        detailedError.error = error;
        throw detailedError;
      })
      .then(res => {
        if (!res.ok) {
          const detailedError = new Error("Server error");
          detailedError.result = res;
          throw detailedError;
        }
        return res;
      })
      .then(res => res.json())
      .then(response => {
        setData({
          volunteer: response
        })
      })
      .catch(error => {
        if (error.result === undefined) {
          setData({
            error: ERROR_TECHNICAL_ERROR
          })
        } else if (error.result.status === 404) {
          setData({
            error: ERROR_RECORD_NOT_FOUND
          })
        } else {
          setData({
            error: ERROR_TECHNICAL_ERROR
          })
        }
      })
  }, [volunteerId]);

  return <DefaultLayout title='Προφίλ Εθελοντή' crumbs={crumbs}>
    <div className='row content-section'>
    {
      (data === undefined ||
       ( data.volunteer === undefined && data.error === undefined )) &&
        <div>Loading</div>
    }
    {
      data && data.volunteer !== undefined &&
      <VolunteerProfile volunteer={data.volunteer} />
    }
    {
      data && data.error === ERROR_TECHNICAL_ERROR &&
      <div>Error: Technical error</div>
    }
    {
      data && data.error === ERROR_RECORD_NOT_FOUND &&
      <div>Error: Record not found</div>
    }
  </div>
  </DefaultLayout>
}

export default function Volunteer() {
  return <Router>
    <VolunteerView path='/volunteer/:volunteerId' />
    <Error_404 default />
  </Router>
}